import React from 'react';
import { graphql } from 'gatsby';
import ReviewHeroSection from 'components/Page/Review/ReviewHeroSection';
import ReviewCardsSection from 'components/Page/Review/ReviewCardsSection';
import ContentfulRowContent from 'components/Contentful/ContentfulRowContent';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from '../layouts/websiteLayout';

const ReviewPageTemplate = (props) => {
  const { contentfulComponentCollection } = props.data;
  let reverse = true;

  const getEntry = (key, value) =>
    contentfulComponentCollection.components.find((component) => component[key] === value);

  const hero = getEntry('__typename', 'ContentfulDynamicHero');
  const heroReview = getEntry('internalName', 'Reviews Page | Hero | Review');
  const seoData = getEntry('__typename', 'ContentfulSeo');
  const rowContent = getEntry('__typename', 'ContentfulRowContent');

  const seo = createSEO(
    {
      ...seoData,
      pageCategory: 'Review',
    },
    props,
  );

  const reviewCards = contentfulComponentCollection?.components?.filter(
    (component) =>
      component.__typename === 'ContentfulCard' || component.__typename === 'ContentfulCardsBlock',
  );

  const sections = reviewCards
    .map((review) => {
      const isVideo = review.__typename === 'ContentfulCard';

      if (!isVideo) {
        reverse = !reverse;
      }
      return {
        type: isVideo ? 'video' : 'icon',
        cards: review,
        reverse,
      };
    })
    .slice(1);

  return (
    <WebsiteLayout headerTheme="TRANS_BG_WHITE_COLOR" hero={{}}>
      <SEO {...seo} />
      <ReviewHeroSection hero={hero} review={heroReview} />
      <ReviewCardsSection title="Hear it from our customers." sections={sections} />
      <ContentfulRowContent {...rowContent} />
    </WebsiteLayout>
  );
};

export default ReviewPageTemplate;

export const ReviewTemplatePageQuery = graphql`
  query ReviewTemplatePageQuery {
    contentfulComponentCollection(contentful_id: { eq: "6uBGHxwImJDEekGRfqq9tD" }) {
      ...ComponentCollection
    }
  }
`;
