import React from 'react';
import styled from 'styled-components';
import Cards from 'components/Core/Cards';

const getName = (card) => {
  const first = card?.person?.firstName;
  const last = card?.person?.lastName;

  return `${first || ''}${last ? ` ${last}` : ''} `;
};

const getTitle = (card) => {
  const position = card?.person?.jobTitle;
  const company = card?.person?.company?.name;

  return position || company;
};

const ReviewCardsSection = ({ background, title, sections, ...otherProps }) => (
  <SectionContainer background={background} {...otherProps}>
    <div className="section-review-card--container">
      <h2 className="section-review-card--title">{title}</h2>
      {sections?.map((section, index) => {
        return section.type === 'icon' ? (
          <IconCardSection section={section} key={index} />
        ) : (
          <VideoCardSection section={section} key={index} />
        );
      })}
    </div>
  </SectionContainer>
);

const IconCardSection = ({ section }) => (
  <div className="section-review-card--card-container">
    {section.cards.cards.map((review) => (
      <div className="section-review-card--card-item" key={review.id}>
        <Cards
          bodyType={'review'}
          companyLogo={review?.person?.company?.logo?.fullColor?.image}
          quote={review?.description?.description}
          author={getName(review)}
          company={getTitle(review)}
        />
      </div>
    ))}
  </div>
);

const VideoCardSection = ({ section }) => {
  const card = section.cards;

  return (
    <div className="section-review-card--card-container">
      <div className="section-review-card--card-item">
        <Cards
          type={'Video'}
          bodyType={'review'}
          sideCover={true}
          sideReverse={section.reverse}
          coverVideo={card.coverWistiaVideo}
          companyLogo={card?.person?.company?.logo?.fullColor?.image}
          quote={card?.description?.description}
          author={getName(card)}
          company={getTitle(card)}
        />
      </div>
    </div>
  );
};

const SectionContainer = styled.div`
  background: var(--skyline-1);
  padding: 133px 21px 91px;
  .section-review-card--container {
    margin: 0 auto;
    max-width: 1200px;

    .section-review-card--title {
      font-size: var(--font-size-44);
      font-weight: var(--font-weight-900);
      line-height: var(--line-height-110);
      text-align: center;
      letter-spacing: var(--letter-spacing--0-04);
      color: var(--darkest);
      max-width: 784px;
      margin: 0 auto 60px auto;
    }

    .section-review-card--card-container {
      display: flex;
      margin: 30px -20px 0;

      .wistia-video--wrapper {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .section-review-card--card-item {
        flex: 1;
        margin: 0 20px;
        max-width: calc(100% - 40px);
      }
    }
  }

  @media (max-width: 991px) {
    .section-review-card--container {
      .section-review-card--title {
        margin: 0 auto 30px auto;
      }
      .section-review-card--card-container {
        margin: 0 -10px;

        .section-review-card--card-item {
          margin: 12px 10px;
          max-width: calc(100% - 20px);
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin: 490px 0 0 0;
    padding: 0 21px 91px;

    .section-review-card--container {
      margin: 0 auto;
      padding: 100px 28px 82px;

      .section-review-card--title {
        font-size: var(--font-size-35);
        line-height: 44px;
      }

      .section-review-card--card-container {
        flex-wrap: wrap;

        .section-review-card--card-item {
          flex: auto;
          width: calc(50% - 20px);
        }
      }
    }
  }

  @media (max-width: 575px) {
    margin: 280px 0 0 0;
    
    .section-review-card--container {
      margin: 0 auto;
      padding: 100px 20px 66px;

      .section-review-card--title {
        font-size: var(--font-size-28);
        line-height: 36px;
      }

      .section-review-card--card-container {
        .section-review-card--card-item {
          width: 100%;
        }
      }
    }
  }
`;

export default ReviewCardsSection;
