import React from 'react';
import styled from 'styled-components';
import Cards from 'components/Core/Cards';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { getColor } from 'utils/colorUtility';
import contentfulRichTextOptions from 'components/RichText/options/contentfulRichTextOptions';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import WistiaVideo from 'components/Core/WistiaVideo';

const ReviewHeroSection = ({ hero, review }) => {
  const headingProps = {
    className: 'section-review-hero--title',
  };

  return (
    <SectionContainer background={getColor(hero?.background)}>
      <div className="section-review-hero--wrapper">
        <div className="section-review-hero--container">
          {renderRichText(hero.heading1, contentfulRichTextOptions(headingProps))}
          <div className="section-review-hero--subtitle">{renderRichText(hero.subhead)}</div>
          <div className="section-review-hero--subscribe-content">
            <HeroSubscribeForm
              buttonLabel="Get Demo"
              redirectUrl="/demo"
              dataPosition="Hero"
              revealProps={false}
              notificationMessage=""
            />
          </div>
          <div className="section-review-hero--main">
            <div className="section-review-hero--review-content">
              <Cards
                bodyType={'review'}
                companyLogo={review?.person?.company?.logo?.fullColor?.image}
                quote={review?.description?.description}
                author={`${review?.person?.firstName} ${review?.person?.lastName}`}
                company={review?.person?.jobTitle}
                width="100%"
              />
            </div>
            <div className="section-review-hero--video-content">
              <div className="section-review-hero--video-container">
                {review.coverWistiaVideo && (
                  <WistiaVideo
                    dataPosition="Hero"
                    url={review?.coverWistiaVideo}
                    wrapperStyles={{ height: '100%' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const SectionContainer = styled.div`
  background: white;
  height: 1100px;
  .section-review-hero--wrapper {
    background: ${(props) => props.background};
    height: 630px;
  }

  .section-review-hero--container {
    margin: 0 auto;
    padding: 220px 0 91px;

    .section-review-hero--title {
      font-size: var(--font-size-44);
      line-height: var(--line-height-110);
      text-align: center;
      letter-spacing: var(--letter-spacing--0-05);
      color: var(--white);
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 28px;
    }

    .card-body--container {
      padding: 32px 0 0 0;
    }

    .image--container {
      margin-bottom: 10px;
    }

    .card--container {
      box-shadow: none;
    }

    .card--body {
      background: none;
    }

    .section-review-hero--subtitle p {
      color: var(--white);
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
      margin-top: 35px;
      padding: 0 28px;
      font-size: var(--font-size-p-md);
    }

    .footer-author--container {
      position: relative;
      bottom: inherit;
      margin-top: 20px;
    }

    .section-review-hero--subscribe-content {
      margin-top: 50px;
      padding: 0 28px;
    }

    .section-review-hero--main {
      max-width: 1182px;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      padding: 0 28px;

      .section-review-hero--review-content {
        max-width: 417px;
        margin-top: 60px;
      }

      .section-review-hero--video-content {
        width: 100%;
        padding: 0 0 0 50px;
        position: relative;
        overflow: hidden;

        .section-review-hero--video-container {
          position: relative;
          display: flex;
          height: auto;
          justify-content: end;

          .VideoMainDiv {
            box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .section-review-hero--main {
      margin-top: 140px !important;
      align-items: center;
    }
    .section-review-hero--review-content {
      margin-top: 0 !important;
      max-width: 300px !important;
    }
    .card--body-wrapper.with-footer-author {
      padding: 0;
    }
    .section-review-hero--container .card-body--container {
      padding: 0 !important;
    }
  }

  @media (max-width: 767px) {
    height: 700px;

    .section-review-hero--container {
      margin: 0 auto;
      padding: 145px 0 91px;

      .section-review-hero--title {
        font-size: var(--font-size-35);
        line-height: 44px;
        max-width: 590px;
      }

      .section-review-hero--description {
        font-size: var(--font-size-18);
        line-height: 28px;
        margin-top: 20px;
        max-width: 590px;
      }

      .section-review-hero--subscribe-content {
        margin: 40px auto 0;
        max-width: 590px;
      }

      .section-review-hero--main {
        max-width: 1254px;
        margin: 0 auto;
        margin-top: 48px;
        display: flex;
        flex-direction: column-reverse;

        .section-review-hero--review-content {
          max-width: 100% !important;
          margin-top: 30px !important;
        }

        .section-review-hero--video-content {
          width: 100%;
          padding: 0;
          position: relative;
          margin-top: -150px;

          .section-review-hero--video-container {
            margin-top: 40px;
            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    height: 850px;

    .section-review-hero--wrapper {
      height: 650px;
    }

    .section-review-hero--container {
      margin: 0 auto;
      padding-bottom: 0;

      .section-review-hero--title {
        font-size: var(--font-size-35);
        line-height: 42px;
      }

      .section-review-hero--description {
        font-size: var(--font-size-20);
        line-height: 28px;
        margin-top: 20px;
      }

      .section-review-hero--subscribe-content {
        margin: 40px auto 0;
      }

      .section-review-hero--main {
        flex-direction: column-reverse;

        .section-review-hero--review-content {
          max-width: 100%;
          margin-top: 27px;

          .review-card--content-container {
            .review-card--description {
              font-size: var(--font-size-18);
              line-height: 28px;
            }
          }
        }

        .section-review-hero--video-content {
          padding: 0;

          .section-review-hero--video-container {
            &:before {
              display: block;
              width: 100px;
              height: 13px;
              bottom: -13px;
              right: -13px;
            }

            &:after {
              display: block;
              width: 13px;
              height: 100px;
              bottom: -13px;
              right: -13px;
            }
          }
        }
      }
    }
  }
`;

export default ReviewHeroSection;
